import firstStroy from "../assets/img/story_1.jpg";
import secondStory from "../assets/img/story_2.jpg";
import thirdStory from "../assets/img/story_3.png";
import fourthStory from "../assets/img/NTSHero.jpeg";
import fifthStory from "../assets/img/SmithBlog1_1.jpg";
import launchStory from "../assets/img/launch-hero.jpg";
import TourismInnovationFund from "../assets/img/TIV1.jpeg";
import NTSWorkshop from "../assets/img/groupNTSWorshop.jpeg";
import DomesticMarketingCampaign from "../assets/img/jeep1.jpeg";
import Smithsonian from "../assets/img/image-hero.png";
import crossboundaryStory from "../assets/img/CBHero.png";
import CapBon from "../assets/img/capbon1.png";
import kairouan from "../assets/img/Kairouan_medina1.jpg";
import smiths from "../assets/img/ya1.jpeg";
import construction from "../assets/img/infrastructure1.jpg";
import surveyHero from "../assets/img/CC1.jpeg";
import FolklifeHero from "../assets/img/smith-hero.jpeg";
import Jalta from "../assets/img/Jalta2.png";
import CrossBoundaryAgreement from "../assets/img/USAIDcb.png";
import WomensDay from "../assets/img/women_cover.jpg";
import Baobab from "../assets/img/baobab2.jpg";
import TunisiaCulture from "../assets/img/ich7.jpg";
import KairouanPublicAwareness from "../assets/img/kpa3.jpg";
import TounesLik from "../assets/img/fb3.jpg";
import CulturalSymposium from "../assets/img/symposium-1.jpg";
import FTAV from "../assets/img/ftav-1.jpg";
import FTADD from "../assets/img/ftadd_hero.jpg";
import hunt from "../assets/img/hunt_hero.jpg";
import mouled from "../assets/img/mouled_hero.jpg";
import upskill from "../assets/img/upskill_hero.png";
import ddp from "../assets/img/ddp_1.jpg";
import kairouanSustainable from "../assets/img/cover-kairouan-sustainable.png";
import pathwaysToProf from "../assets/img/pathways-cover.jpg";
import TunisLik from "../assets/img/cover-tounes-lik.png";
import MicroLoan from "../assets/img/micro_finance_cover.png";
import VisitorUsaid from "../assets/img/tozeur_festival_one.jpg";
import ArtisanSupport from "../assets/img/artisan_support_banner.jpeg";
import ProfessionalsTraining from "../assets/img/76-pro-cover.jpg";
import KairouanSchools from "../assets/img/kair-schools-cover.jpeg";
import HospitalityProfessionals from "../assets/img/hospitality_pro_cover.jpeg";
import AcceleratingGrowth from "../assets/img/accelerating-tourism.png";
import AcceleratingTourism from "../assets/img/accelerating_tourism_cover.jpeg";
import TechnicalMarketing from "../assets/img/technical-marketing-support-cover.png";
import DDP from "../assets/img/cover.png";
import Authentic from "../assets/img/authentic_cover.png";
import ElJem from "../assets/img/jem-festival/banner.png";
import SecondAuthenticTunisia from "../assets/img/authentic-tunisia-2/banner.png";
import Matmata from "../assets/img/matmata-international-festival/banner.jpg";
import Tamss from "../assets/img/tamss/hero.png";
import SecondPathways from "../assets/img/pathways-23/hero.png";
import thirtyCultural from "../assets/img/30/banner.png";
import kairouanCampaign from "../assets/img/kairouan-campaign/banner.jpg";
import adventureTourism from "../assets/img/adventure-focused-tourism/cover.png";
import microcred from "../assets/img/microcred/cover.jpg";
import MRPTRanformation from "../assets/img/transformational/banner.png";

const stories = [
  {
    title:
      "US Injects $50 Million into Tunisia’s Tourism Sector with Visit Tunisia Project",
    image: launchStory,
    link: "/stories/visit-tunisia-launch-event",
    date: "02.02.2022",
    category: "Articles",
  },
  {
    title: "Domestic Tourism Survey",
    image: firstStroy,
    link: "/stories/domestic-tourism-survey",
    date: "24.01.2022",
    category: "Articles",
  },
  {
    title: "Visit Tunisia’s 3-week Cross-Country Roadshow",
    image: secondStory,
    link: "/stories/cross-country-roadshow",
    date: "27.01.2022",
    category: "Articles",
  },
  {
    title: "Workshops with Tourism Stakeholders: A Deep Dive into the Industry",
    image: thirdStory,
    link: "/stories/tourism-workshops",
    date: "27.01.2022",
    category: "Articles",
  },
  {
    title:
      "National Tourism Strategy Consultation Event Hailed as ‘Major Success’",
    image: fourthStory,
    link: "/stories/national-tourism-strategy",
    date: "11.04.2022",
    category: "Articles",
  },
  {
    title:
      "Visit Tunisia and Smithsonian Train Event and Festival Organizers Across Tunisia",
    image: fifthStory,
    link: "/stories/smiths-train-event",
    date: "11.04.2022",
    category: "Articles",
  },
  {
    title: "Supporting Innovative Tourism Products and Experiences",
    image: TourismInnovationFund,
    link: "/stories/tourism-innovation-fund",
    date: "18.05.2022",
    category: "Articles",
  },
  {
    title: "Momentum Builds for New National Tourism Strategy",
    image: NTSWorkshop,
    link: "/stories/nts-workshop",
    date: "18.05.2022",
    category: "Articles",
  },
  {
    title: "Inspiring Local Travelers to Explore Tunisia's Wonders",
    image: DomesticMarketingCampaign,
    link: "/stories/domestic-marketing-campaign",
    date: "18.05.2022",
    category: "Articles",
  },
  {
    title: "Opening Doors for Tunisian Event and Festival Organizers",
    image: Smithsonian,
    link: "/stories/smithsonian-incubator-program",
    date: "18.05.2022",
    category: "Articles",
  },
  {
    title:
      "USAID Launches Investment Facilitation Support to Grow Tunisia’s Tourism Sector",
    image: crossboundaryStory,
    link: "/stories/crossboundary",
    date: "19.05.2022",
    category: "Articles",
  },
  {
    title: "Helping Promising Tourism SMEs Secure Investment",
    image: crossboundaryStory,
    link: "/stories/helping-sme",
    date: "16.06.2022",
    category: "Articles",
  },
  {
    title:
      "Domestic Tourism Campaign in High Gear as Summer Tourism Season Begins",
    image: CapBon,
    link: "/stories/domestic-tourism-campaign",
    date: "16.06.2022",
    category: "Articles",
  },
  {
    title: "Planning for Sustainable Tourism Growth in Underserved Regions",
    image: kairouan,
    link: "/stories/destination-development-planning",
    date: "16.06.2022",
    category: "Articles",
  },
  {
    title: "Taking Tunisia's Events and Festivals to the Next Level",
    image: smiths,
    link: "/stories/smithsonian-incubator-program-update",
    date: "16.06.2022",
    category: "Articles",
  },
  {
    title: "Improving Tunisia’s Tourism Infrastructure",
    image: construction,
    link: "/stories/prequalification-for-construction-firms",
    date: "16.06.2022",
    category: "Articles",
  },
  {
    title:
      "USAID Visit Tunisia Launches International Survey for National Tourism Strategy",
    image: surveyHero,
    link: "/stories/national-tourism-strategy-online-survey",
    date: "20.07.2022",
    category: "Articles",
  },
  {
    title:
      "Tunisian Festival Organizers Draw Inspiration From Smithsonian Folklife Festival",
    image: FolklifeHero,
    link: "/stories/smithsonian-folklife-festival",
    date: "20.07.2022",
    category: "Articles",
  },
  {
    title: "Domestic Marketing Campaign Spotlights Djerba, Tabarka, Jalta",
    image: Jalta,
    link: "/stories/domestic-marketing-campaign-update",
    date: "20.07.2022",
    category: "Articles",
  },
  {
    title:
      "USAID Visit Tunisia/CrossBoundary Ink Transaction Advisory Deals With Promising Tourism Projects",
    image: CrossBoundaryAgreement,
    link: "/stories/crossboundary-agreements",
    date: "21.07.2022",
    category: "Articles",
  },
  {
    title:
      "Meet the Tunisian Women at the Forefront of Tunisia’s Tourism Industry",
    image: WomensDay,
    link: "/stories/womens-day",
    date: "12.08.2022",
    category: "Articles",
  },
  {
    title:
      "USAID Visit Tunisia/Baobab Partner to Provide Critical Funding to 800 Tourism Entrepreneurs",
    image: Baobab,
    link: "/stories/usaid-visit-tunisia-baobab-partner",
    date: "16.08.2022",
    category: "Articles",
  },
  {
    title: "Rediscovering Tunisia’s Vast Cultural Heritage",
    image: TunisiaCulture,
    link: "/stories/rediscovering-tunisias-vast-cultural-heritage",
    date: "16.08.2022",
    category: "Articles",
  },
  {
    title:
      "Hundreds of Kairouan Youth Clean Up Streets as Part of USAID Visit Tunisia-Backed Campaign",
    image: KairouanPublicAwareness,
    link: "/stories/kairouan-public-awareness-campaign",
    date: "26.09.2022",
    category: "Articles",
  },
  {
    title:
      "“Tounes Lik” Campaign Reaches Largest Audience Yet as it Moves to Bizerte, Haouaria, Kerkennah",
    image: TounesLik,
    link: "/stories/tounes-lik",
    date: "26.09.2022",
    category: "Articles",
  },
  {
    title:
      "Inaugurating Tunisia’s Living Culture Initiative with a 3-Day Cultural Heritage Symposium",
    image: CulturalSymposium,
    link: "/stories/cultural-heritage-symposium",
    date: "29.09.2022",
    category: "Articles",
  },
  {
    title:
      "USAID Visit Tunisia Signs Multi-Year Cooperation  with the Tunisian Federation of Travel and Tourism Agencies (FTAV)",
    image: FTAV,
    link: "/stories/visit-tunisia-ftav-cooperation",
    date: "29.09.2022",
    category: "Articles",
  },
  {
    title:
      "Engaging Kairouan Scavenger Hunt Combines Cultural Heritage Education With Fun for Tunisia’s Youth",
    image: hunt,
    link: "/stories/kairouan-scavenger-hunt",
    date: "28.10.2022",
    category: "Articles",
  },
  {
    title:
      " USAID Visit Tunisia Signs Multi-Year Cooperation with Tunisia’s first Destination Management Organization, Fédération Tourisme Authentique Destination Dahar (FTADD)",
    image: FTADD,
    link: "/stories/ftadd",
    date: "28.10.2022",
    category: "Articles",
  },
  {
    title:
      "USAID Visit Tunisia Smithsonian Folklife Incubator supports Mouled Festival, welcoming 1.2 million visitors to Kairouan",
    image: mouled,
    link: "/stories/kairouan-mouled",
    date: "28.10.2022",
    category: "Articles",
  },
  {
    title:
      "UPSKILL: 27 certified professionals ready to take their tourism federations and associations to the next competitive level",
    image: upskill,
    link: "/stories/upskill",
    date: "29.11.2022",
    category: "Articles",
  },
  {
    title:
      "First Regional Destination Development Plan Unveiled to Local Stakeholders in Tozeur",
    image: ddp,
    link: "/stories/ddp-impact",
    date: "30.11.2022",
    category: "Articles",
  },
  {
    title:
      "Over 450 Tunisian youth and persons with disabilities bringing awareness about sustainable tourism in Kairouan",
    image: kairouanSustainable,
    link: "/stories/sustainable-tourism-in-kairouan",
    date: "27.12.2022",
    category: "Articles",
  },
  {
    title:
      "Pathways to Professionalism: 75 empowered professionals to improve the quality of hotel services in Tunis and Hammamet",
    image: pathwaysToProf,
    link: "/stories/pathways-to-professionalism",
    date: "27.12.2022",
    category: "Articles",
  },
  {
    title:
      "Tounes Lik: Inspiring Tunisians to Embark on Domestic Tourism Adventures",
    image: TunisLik,
    link: "/stories/tounes-lik-inspires",
    date: "30.12.2022",
    category: "Articles",
  },
  {
    title:
      "Providing Loan and Micro-finance Grant Support to 800 Micro and Very Small Tunisian Businesses Across Tunisia ",
    image: MicroLoan,
    link: "/stories/providing-loan-and-micro-finance-grant-support",
    date: "30.01.2023",
    category: "Articles",
  },
  {
    title:
      "10,000 Visitors Enjoy the USAID-Smithsonian Folklife Supported Tozeur International Oasis Festival",
    image: VisitorUsaid,
    link: "/stories/ten-thousand-visitors-enjoy-the-international-oasis-festival-in-tozeur",
    date: "30.01.2023",
    category: "Articles",
  },
  {
    title:
      "Supporting over 280 Artisans and Culture-Bearers in Creating Tourism Experiences in Gabes, Kairouan, Tozeur and, Tabarka.",
    image: ArtisanSupport,
    link: "/stories/supporting-over-280-artisans",
    date: "31.01.2023",
    category: "Articles",
  },
  {
    title:
      "USAID Visit Tunisia Activity Engages 300 Children in Kairouan’s Schools to Raise Awareness About Sustainable Tourism",
    image: KairouanSchools,
    link: "/stories/usaid-visit-tunisia-activity-engages-300-children",
    date: "27.02.2023",
    category: "Articles",
  },
  {
    title:
      "76 Professionals Trained, Accelerating Tourism Association Competitiveness in Tunis and Tunisia’s Southern and Western Regions",
    image: ProfessionalsTraining,
    link: "/stories/76-professionals-trained",
    date: "27.02.2023",
    category: "Articles",
  },
  {
    title:
      " Recognizing over 350 Hospitality Professionals from 14 Partner Hotels in Tunis and Hammamet",
    image: HospitalityProfessionals,
    link: "/stories/recognizing-over-350-hospitality-professionals",
    date: "29.03.2023",
    category: "Articles",
  },
  {
    title: "Accelerating Tunisia’s Tourism Growth and Diversity",
    image: AcceleratingGrowth,
    link: "/stories/accelerating-tunisias-tourism-growth-and-diversity",
    date: "29.03.2023",
    category: "Articles",
  },
  {
    title:
      "Accelerating Tourism Businesses’ Profitability Through Capacity-Building Workshops for over 140 SMEs from Key Regions Across Tunisia",
    image: AcceleratingTourism,
    link: "/stories/accelerating-tourism-businesses",
    date: "29.03.2023",
    category: "Articles",
  },
  {
    title:
      "Technical Marketing Support for Destination Dahar, Tunisia’s First Destination Management Organization, Begins",
    image: TechnicalMarketing,
    link: "/stories/technical-marketing-support-for-destination-dahar",
    date: "30.03.2023",
    category: "Articles",
  },
  {
    title:
      "Paving the Way to the Long-term Sustainable Tourism Sector Growth of Tabarka/Aïn Draham, Kairouan, Tozeur, Gabes/Matmata, Tataouine and, Kebili/Douz",
    image: DDP,
    link: "/stories/ddp",
    date: "28.04.2023",
    category: "Articles",
  },
  {
    title:
      "Authentic Tunisia Campaign: 12-Day Journey through Tunisia’s Hidden Gems and Authentic Experiences",
    image: Authentic,
    link: "/stories/authentic-tunisia-campaign",
    date: "28.04.2023",
    category: "Articles",
  },
  {
    title:
      "Thousands Visit El Jem Amphitheater for Annual Family-Friendly Festival Filled To Celebrate Tunisia’s Roman History",
    image: ElJem,
    link: "/stories/el-jem-festival",
    date: "05.06.2023",
    category: "Articles",
  },
  {
    title:
      "Authentic Tunisia: Inspiring International Tourists to Add Tunisia to Their Travel Bucklet-List (part 1)",
    image: SecondAuthenticTunisia,
    link: "/stories/authentic-tunisia-2",
    date: "08.06.2023",
    category: "Articles",
  },
  {
    title:
      "The International Festival of Matmata: Creating Connections Between Local Businesses and Thousands of Visitors",
    image: Matmata,
    link: "/stories/international-festival-of-matmata",
    date: "09.06.2023",
    category: "Articles",
  },
  {
    title:
      " سياحتنا (“Our Tourism”): USAID, TAMSS, and Advans Tunisie Launch Entrepreneur Support Program for Tourism Sector in Tunisia",
    image: Tamss,
    link: "/stories/tamss",
    date: "06.07.2023",
    category: "Articles",
  },
  {
    title:
      " Marking the Success of 450 Trained Professionals of the Hospitality Sector in Tunisia",
    image: SecondPathways,
    link: "/stories/pathways-june-2023",
    date: "06.07.2023",
    category: "Articles",
  },
  {
    title:
      "Bringing 30 Cultural Experiences from Tozeur, Kebili, Tabarka, Kairouan, Tataouine, and Gabes to the Market.",
    image: thirtyCultural,
    link: "/stories/bringing-30-cultural-experiences-to-the-market",
    date: "07.08.2023",
    category: "Articles",
  },
  {
    title:
      "Over 1,000 citizens and children: the New Ambassadors for Sustainable Tourism in Kairouan.",
    image: kairouanCampaign,
    link: "/stories/kairouan-campaign",
    date: "07.08.2023",
    category: "Articles",
  },
  {
    title:
      "Boosting the Visibility and Bookings of 12 Adventure-Focused Tourism Experiences.",
    image: adventureTourism,
    link: "/stories/adventure-focused-tourism",
    date: "07.08.2023",
    category: "Articles",
  },
  {
    title:
      "Accelerating Tunisian Women-Led Micro and Very Small Enterprises’ Recovery and Growth",
    image: microcred,
    link: "/stories/microcred",
    date: "17.08.2023",
    category: "Articles",
  },
  {
    title:
      "The Transformational Market Readiness Journey of 60 Small Businesses in Kebili, Gabes, Jendouba, Kairouan, Tozeur, and Tataouine ",
    image: MRPTRanformation,
    link: "/stories/transformational-market-readiness-journey",
    date: "20.09.2023",
    category: "Articles",
  },
];

export default stories;
