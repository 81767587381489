import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import moment from "moment";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Stories from "../utils/stories";

const VTStories = () => {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { fields: createdAt, order: ASC }) {
        nodes {
          createdAt
          slug
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.77
                  layout: CONSTRAINED
                  height: 1248
                  width: 1996
                  transformOptions: { fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Library | Visit Tunisia Project" />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <div className="views-element-container">
                <section className="stories-section">
                  <div className="container">
                    <h2>Our Stories</h2>
                  </div>
                  <div className="container">
                    <div className="stories d-flex flex-lg-row flex-md-row flex-column flex-wrap grid">
                      {allStrapiArticle.nodes.reverse().map((article) => (
                        <div className="card">
                          <a href={`/stories/${article.slug}`} target="_blank">
                            <GatsbyImage
                              image={getImage(article.image?.localFile)}
                              alt={article.title}
                              style={{
                                width: "100%",
                                height: "260px",
                                marginBottom: "16px",
                                borderRadius: "26px",
                                display: "block",
                              }}
                            />

                            <div className="card-body">
                              <h5 className="card-title"> {article.title} </h5>
                              <div className="d-flex">
                                <span className="storie-categorie">
                                  Articles
                                </span>
                                <small>
                                  {moment(article.createdAt).format(
                                    "DD-MM-YYYY"
                                  )}
                                </small>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                      {Stories.slice(0)
                        .reverse()
                        .map((story) => (
                          <div className="card">
                            <a href={story.link} target="_blank">
                              <img
                                src={story.image}
                                width={1996}
                                height={1248}
                                alt={story.title}
                                typeof="Image"
                              />
                              <div className="card-body">
                                <h5 className="card-title"> {story.title} </h5>
                                <div className="d-flex">
                                  <span className="storie-categorie">
                                    {story.category}
                                  </span>
                                  <small>{story.date}</small>
                                </div>
                              </div>
                            </a>
                          </div>
                        ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default VTStories;
